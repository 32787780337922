<template>
  <DelayHydration>
    <component
      :is="frontendCeLayout"
      :uid="uid"
      :index="index"
      :appearance="appearance"
      :case-study-list="caseStudyList"
      :hide-checkbox="hideCheckbox"
      :page-data="minifiedPageData"
      :page-tag-list="pageTagList"
      :lang="currentLocale"
      class="t3-ce-category-boxes"
    />
  </DelayHydration>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue'

import { useCurrentLocale, useT3Api } from '#imports'

import type { T3CeCategoryBoxesProps } from '~/types'

const props = withDefaults(defineProps<T3CeCategoryBoxesProps>(), {
  hideCheckbox: false
})

const currentLocale = useCurrentLocale()

const caseStudyList = computed(() => {
  if (!Array.isArray(props.projects)) return []
  return props.projects.map(({ id, content }) => ({ id, ...content }))
})

const { pageData } = useT3Api()
const minifiedPageData = {
  slug: pageData.value?.slug,
  i18n: pageData.value?.i18n,
  badge: pageData.value?.badge,
  system: pageData.value?.system
}

const pageTagList = computed(() => {
  const pageCategories = pageData.value?.categoryPages?.categories
  return (Array.isArray(pageCategories) ? pageCategories : []).map(
    ({ category }) => category?.name
  )
})

const CaseStudyTileList = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeCategoryBoxes/CaseStudyTileList')
)
const CaseStudySlider = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeCategoryBoxes/CaseStudySlider')
)
const CaseStudyTileSlider = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeCategoryBoxes/CaseStudyTileSlider')
)
const CaseStudyHeroBanner = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeCategoryBoxes/CaseStudyHeroBanner')
)

const frontendCeLayout = computed(() => {
  switch (props.appearance?.layout) {
    case 'caseStudySlider':
      return CaseStudySlider
    case 'caseStudyTileSlider':
      return CaseStudyTileSlider
    case 'caseStudyHeroBanner':
      return CaseStudyHeroBanner
    default:
      return CaseStudyTileList
  }
})
</script>
